<template>
  <div class='details'>
    <back />
    <div class="con" v-loading="loading">
      <h4>基本信息</h4>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="ruleForm" :disabled="disabled">
        <el-row>
          <el-col :span="8">
            <el-form-item label="优惠券名称" prop="couponName">
              <el-input v-model="ruleForm.couponName" placeholder="请输入优惠券名称"></el-input>
            </el-form-item>
            <el-form-item label="类型" prop="couponType">
              <el-select v-model="ruleForm.couponType" placeholder="请选择类型">
                <el-option v-for="(item, index) in options1" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="优惠起始日期" prop="startDate">
              <el-date-picker v-model="ruleForm.startDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="总数量" prop="totalCount">
              <el-input v-model.number="ruleForm.totalCount" type="number" placeholder="请输入总数量"></el-input>
            </el-form-item>
            <el-form-item v-if="ruleForm.couponType == '1'" label="减免金额" prop="reductionAmount">
              <el-input v-model.number="ruleForm.reductionAmount" type="number" placeholder="请输入减免金额" @input="reductionAmountChange"></el-input>
            </el-form-item>
            <el-form-item v-if="ruleForm.couponType == '2'" label="折扣率" prop="discountRate" :rules="ruleForm.couponType == 2 ? discountRate : null">
              <el-input v-model.number="ruleForm.discountRate" type="number" placeholder="请输入折扣率">
                <span slot="suffix">%</span>
              </el-input>
            </el-form-item>
            <el-form-item v-if="ruleForm.couponType == '3'" label="固定金额" prop="fixedAmount">
              <el-input v-model.number="ruleForm.fixedAmount" type="number" placeholder="请输入固定金额"></el-input>
            </el-form-item>
            <el-form-item label="优惠截止日期" prop="endDate">
              <el-date-picker v-model="ruleForm.endDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="订单金额" prop="orderAmount" :rules="ruleForm.couponType == 1 ? orderAmount : null">
              <el-input v-model.number="ruleForm.orderAmount" type="number" placeholder="请输入订单金额"></el-input>
            </el-form-item>
            <el-form-item label="是否启用" prop="couponStatus">
              <el-select v-model="ruleForm.couponStatus" placeholder="请选择是否启用">
                <el-option label="启用" value="1"></el-option>
                <el-option label="停用" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <div v-if="sign !== '查看'" class="btn">
          <el-button type="primary" @click="submitForm('ruleForm')">{{ sign }}</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
function createInitRuleForm () {
  return {
    couponName: '', // 优惠券名称
    couponStatus: '', // 状态：1正常；3停用；9删除
    couponType: '1', // 类型：1满减券；2折扣；3代金券（固定金额）
    reductionAmount: '', // 减免金额（couponType=1，满减券时使用）
    discountRate: '', // 折扣率（couponType=2，折扣券时使用）
    fixedAmount: '', // 固定金额（couponType=3，代金券时使用）
    totalCount: '', // 总数量
    orderAmount: '', // 订单金额
    productType: '1', // 商品类型：1全场通用；2指定商品
    startDate: '', // 优惠券起始日期
    endDate: '', // 优惠券截止日期
  }
}
export default {
  data () {
    return {
      loading: false,
      id: this.$route.query.id || null,
      sign: this.$route.query.sign,
      options1: [
        { value: '1', label: '满减券' },
        { value: '2', label: '折扣' },
        // { value: '3', label: '代金券' },
      ],
      disabled: false,
      ruleForm: createInitRuleForm(),
      productList: [],
      checkList: [],
      rules: {
        couponName: [{ required: true, message: '请输入优惠券名称', trigger: ['blur', 'change'] }],
        totalCount: [{ required: true, message: '请输入总数量', trigger: ['blur', 'change'] }],
        couponType: [{ required: true, message: '请选择类型', trigger: ['blur', 'change'] }],
        couponStatus: [{ required: true, message: '请选择是否启用', trigger: ['blur', 'change'] }],
        productType: [{ required: true, message: '请选择适用商品', trigger: ['blur', 'change'] }],
        startDate: [{ required: true, message: '请选择日期', trigger: ['blur', 'change'] }],
        endDate: [{ required: true, message: '请选择日期', trigger: ['blur', 'change'] }],
        reductionAmount: [
          { required: true, message: '请输入减免金额', trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (Number(value) < 0) {
                callback(new Error('减免金额不能小于 0'));
              } else if (Number(value) > Number(this.ruleForm.orderAmount)) {
                value = ''
                callback(new Error('减免金额不能大于订单金额'));
              } else {
                callback();
              }
            },
            trigger: ['blur', 'change'],
          }
        ],
        fixedAmount: [{ required: true, message: '请输入固定金额', trigger: ['blur', 'change'] }],
      },
      orderAmount: [{ required: true, message: '请输入订单金额', trigger: ['blur', 'change'] }],
      checkListRules: [{ required: true, message: '请选择该优惠券指定的商品', trigger: ['blur', 'change'] }],
      discountRate: [
        { required: true, message: '请输入折扣率', trigger: ['blur', 'change'] },
        {
          validator: (rule, value, callback) => {
            if (value < 0 || value > 100) {
              callback(new Error('折扣率不能小于 0 或大于 100 '));
            } else {
              callback();
            }
          },
          trigger: ['blur', 'change'],
        }
      ],

      multipleSelection: [], // 选中数据
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      dialogVisible: false,
    }
  },
  created () {
    this.disabled = this.sign == '查看' ? true : false
    document.title = `${this.sign}优惠券`
    if (this.id) this.getDetail()
  },
  methods: {
    getDetail () {
      this.loading = true
      this.$axios.get(`${this.$api.couponSelectInfo}/${this.id}`).then(res => {
        if (res.data.code == 100) {
          this.ruleForm = res.data.result
          this.loading = false
        } else {
          this.loading = false
        }
      })
    },
    // 切换每页显示的条数
    handleSizeChange (e) {
      this.productList = []
      this.pageSize = e
      this.getProductList()
    },
    // 换页
    handleCurrentChange (e) {
      this.productList = []
      this.currentPage = e
      this.getProductList()
    },
    reductionAmountChange () {
      // console.log(typeof e);
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = Object.assign({}, this.ruleForm)
          let api = ''
          if (this.sign == '新增') {
            api = this.$api.couponSave
          } else {
            api = this.$api.couponUpdate
            params.id = this.id
          }
          params.shopId = this.$store.state.userInfo.shopId
          this.$axios.post(api, params).then(res => {
            if (res.data.code == 100) {
              this.$message.success(`${this.sign}成功`)
              setTimeout(() => {
                this.$router.go(-1)
              }, 500);
            }
          })
        } else {
          this.$message.error('提交失败');
          return false;
        }
      });
    },
    resetForm (formName) {
      this.ruleForm = createInitRuleForm();
      this.$refs[formName].resetFields();
    },
  },
}
</script>

<style scoped lang='scss'>
.details {
  .con {
    padding: 20px;

    .ruleForm {
      margin-top: 30px;

      /deep/.el-form-item__label {
        padding-right: 30px;
      }

      .el-select,
      .el-input {
        width: 300px;
      }

      /deep/ input[disabled] {
        color: #272727;
        border: 1px solid #E5E7E6;
        background-color: #fff;
        cursor: default;
      }

      .btn {
        margin-top: 100px;
        text-align: center;

        .el-button {
          width: 120px;
        }
      }
    }
  }
}
</style>
